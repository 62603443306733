.btn1,
.btn2 {
  color: #000;
  border: 0;
  font-size: 14px;
  background: none;
  padding: 0;
  margin-right: 0.36rem;
}
/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/
.titlePStyle {
  /* margin-bottom: 0.2rem; */
  margin-top: 0.2rem;
  /* padding-top: 0.1rem; */
  margin-right: 7rem;
  transform: rotate(180deg);
}
.qrcode {
  width: 0.3rem;
}
.fengXiangCode {
  position: absolute;
  bottom: 6.5rem;
  left: 3.2rem;
}
.spanStyle {
  margin-right: 7rem;
}
.op_container {
  flex: 1;
  text-align: left;
  margin-left: 0.5rem;
}
.title {
  background-position: center top;
  background-size: 100% 100%;
}
.title.bgVip {
  height: 100%;
  background-image: url('../../assets/imgs/share/poster.png');
}
.title .shareFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.4rem 0.6rem;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);
}
.title .shareFooter .codeImgStyle {
  width: 1.29rem;
  height: 1.29rem;
}
.title .shareFooter .codeImgStyle img {
  width: 100%;
  height: 100%;
}
.title .shareFooter .yaoQingStyle {
  color: #000;
  font-size: 16px;
  margin: 0.15rem 0 0.2rem;
}
.title .shareFooter .rightBtn {
  margin-right: 10px;
}
.commonHead1 {
  background-image: url(../../assets/imgs/yao_bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
  padding: 0 0.3rem;
}
.commonHead1 .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
}
.commonHead1 .left {
  position: absolute;
  left: 0;
  top: 0;
}
.commonHead1 .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHead1 .left .icon .icon_arrL {
  border-top: 0.04rem solid #ffffff;
  border-left: 0.04rem solid #ffffff;
}
.commonHead1 .left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHead1 .title {
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  text-transform: capitalize;
}
.footerBtn {
  color: #ffffff;
  font-size: 0.58rem;
  width: 4.24rem;
  height: 1.54rem;
  padding-bottom: 0.14rem;
  border-radius: 0.77rem;
  background: #ff4b04 url(../../assets/imgs/share/VIP.png);
  background-size: 100% 100%;
  margin: 0 auto;
}
.footerBtn span {
  font-weight: bold;
}
.layoutBox {
  height: 100vh;
  padding: 0.1rem 0.3rem 0.5rem;
  background-image: url(../../assets/imgs/yao_bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
  margin-top: -0.88rem;
}
.layoutBox .shareAds {
  margin: 0 auto;
  margin-top: 1.08rem;
  width: 6.25rem;
  height: 1.27rem;
}
.layoutBox .shareAds img {
  display: block;
  width: 100%;
}
.layoutBox .yao_head_zi {
  width: 4.17rem;
  height: 0.42rem;
  line-height: 0.42rem;
  margin: 0 auto;
  margin-top: 0.22rem;
  text-align: center;
  color: #2E62FF;
  font-size: 0.24rem;
  padding: 0 0.4rem;
  background-color: #FFFFFF;
  border-radius: 0.21rem;
  position: relative;
  z-index: 10;
}
.layoutBox .shareAds1 {
  margin: 0 auto;
  margin-top: -0.29rem;
  width: 6.02rem;
  height: 4.91rem;
}
.layoutBox .shareAds1 img {
  display: block;
  width: 100%;
}
.layoutBox .shareSteps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.88rem;
  padding: 0.32rem 0.64rem 0.55rem 0.64rem;
}
.layoutBox .shareSteps .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layoutBox .shareSteps .item .icon {
  font-size: 0.33rem;
  width: 0.89rem;
  height: 0.89rem;
  border-radius: 50%;
  background-color: #CCD9FF;
  color: #2E62FF;
  line-height: 0.89rem;
  text-align: center;
}
.layoutBox .shareSteps .item .txt {
  color: #333333;
  margin-top: 0.2rem;
  text-align: center;
  font-size: 0.28rem;
  width: 1.12rem;
}
.layoutBox .shareSteps .item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layoutBox .shareSteps .item1 .icon {
  font-size: 0.33rem;
  width: 0.89rem;
  height: 0.89rem;
  border-radius: 50%;
  background-color: #CCD9FF;
  color: #2E62FF;
  line-height: 0.89rem;
  text-align: center;
}
.layoutBox .shareSteps .item1 .txt {
  margin-top: 0.2rem;
  text-align: center;
  font-size: 0.28rem;
  width: 1.68rem;
}
.layoutBox .shareSteps .split {
  margin-top: -1.2rem;
  width: 0.38rem;
}
.layoutBox .shareSteps .split img {
  width: 100%;
  height: 100%;
}
.layoutBox .shareInfo {
  margin-top: -0.64rem;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 0.21rem;
}
.layoutBox .shareInfo .top {
  padding: 0.3rem 0.6rem 0 0.6rem;
}
.layoutBox .shareInfo .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  font-size: 0.24rem;
}
.layoutBox .shareInfo .item .item_left {
  display: flex;
  align-items: center;
}
.layoutBox .shareInfo .item:last-child {
  margin-bottom: 0;
}
.layoutBox .shareInfo .item .inp {
  font-family: PingFangSC-Medium;
  font-size: 0.32rem;
  color: #333;
  height: 0.7rem;
  line-height: 0.71rem;
  padding: 0 0.1rem 0 0.35rem;
  display: flex;
  justify-content: space-between;
}
.layoutBox .shareInfo .item .inp .link {
  width: 2.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layoutBox .shareInfo .item .copy {
  width: 0.97rem;
  height: 0.41rem;
  font-size: 0.24rem;
  color: #2E62FF;
  border: 1px solid #527EFF;
  border-radius: 0.325rem;
}
.layoutBox .shareReward {
  padding: 0.25rem;
  background: #ffffff;
  border-radius: 0.12rem;
}
.layoutBox .shareReward .caption {
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.15rem;
}
.layoutBox .shareReward .caption h3 {
  font-size: 0.32rem;
}
.posterLayout {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 4, 15, 0.4);
}
.posterCode {
  position: fixed;
  z-index: 99;
  left: 1.02rem;
  right: 1.02rem;
  top: 50%;
  width: 5.47rem;
  height: 6.06rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../../assets/imgs/mianduimian.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.posterCode .title {
  font-family: PingFangSC-Regular;
  color: #333333;
  font-size: 0.32rem;
  margin: 0 auto;
  text-align: center;
  padding-top: 0.62rem;
  font-weight: bold;
}
.posterCode .poster img {
  display: block;
  width: 100%;
}
.posterCode .poster .code {
  margin-top: 0.58rem;
  margin-left: 1.07rem;
  width: 3.32rem;
  height: 3.36rem;
}
.posterCode .close {
  width: 0.8rem;
  height: 0.8rem;
  background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
  background-size: 0.6rem 0.6rem;
  margin: 1.5rem auto 0;
}
.bottom {
  padding: 0.25rem 0;
  display: flex;
  justify-content: space-between;
}
.bottom .btn {
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  width: 3.26rem;
  height: 0.9rem;
  line-height: 0.9rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
}
